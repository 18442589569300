import SvgLayer from "./SvgLayer";
import React, { useEffect, useState } from "react";
import { DraggableCore, DraggableData } from "react-draggable";
import {
  MouseTouchEvent,
  MouseEvent,
} from "react-draggable/build/cjs/utils/types";

import { getOffsetProc, key2pos, offsetToKey } from "../chessUtils/util";
import { Z_UNDER } from "./animations";
import DrawLayer from "./DrawLayer";
import pieceStyles from "./Piece.module.css";

let lastDragKey, currentKey, id;

const isSafari =
  process.browser &&
  /Safari/.test(navigator.userAgent) &&
  /Apple Computer/.test(navigator.vendor);

export const isRightButton = (e: MouseTouchEvent): boolean =>
  e.buttons === 2 || e.button === 2;

function DragBoard({
  freeMove,
  lastPuzzleMove,
  puzzleDone,
  pieceRefs,
  movePiece,
  possibleMoves,
  squareSize,
  playingWhite,
  position,
  highLightKey,
  bestMove,
  showLastMove,
  lastMove,
  lastMoveColor,
  settings,
  children,
}) {
  const halfSquare = squareSize / 2;
  const [goast, setGoast] = useState(null);
  const [moveHelp, setMoveHelp] = useState(null);
  const [moveSquarePos, setMoveSquarePos] = useState(null);
  const [shapes, setShapes] = useState([]);

  const [drawMode, setDrawMode] = useState(false);

  function canMove(to) {
    const moves = possibleMoves[currentKey];
    return Array.isArray(moves) && moves.indexOf(to) >= 0;
  }

  useEffect(() => {
    if (id && possibleMoves[currentKey]) {
      if (canMove(lastDragKey)) {
        const dragPos = key2pos(lastDragKey);
        setMoveSquarePos(dragPos);
      }
    }
  }, [possibleMoves]);

  function onDrag(e, data) {
    if (!id) {
      return;
    }

    const posProc = {
      x: ((data.x - halfSquare) * 100) / squareSize,
      y: ((data.y - halfSquare) * 100) / squareSize,
    };

    const pieceRef = pieceRefs.current[id];
    // piece.setOffset(posProc);
    pieceRef.style.transform = `translate(${posProc.x}%, ${posProc.y}%)`;

    const dragKey = offsetToKey(data.x, data.y, playingWhite, squareSize);

    if (lastDragKey != dragKey) {
      if (canMove(dragKey)) {
        setMoveSquarePos(key2pos(dragKey));
      } else {
        setMoveSquarePos(null);
      }
      lastDragKey = dragKey;
    }
  }

  function onStop(e: MouseTouchEvent, data: DraggableData) {
    if (!id) {
      return;
    }
    setMoveSquarePos(null);

    const to = offsetToKey(data.x, data.y, playingWhite, squareSize);
    const pieceRef = pieceRefs.current[id];
    if (canMove(to)) {
      const goal = getOffsetProc(to, playingWhite);
      if (pieceRef) {
        pieceRef.style.transform = `translate(${goal.x}%, ${goal.y}%)`;
      }
      movePiece({ from: currentKey, to });
    } else {
      const goal = getOffsetProc(currentKey, playingWhite);
      if (pieceRef) {
        pieceRef.style.transform = `translate(${goal.x}%, ${goal.y}%)`;
      }
    }

    if (pieceRef && pieceRefs.current[id]) {
      pieceRefs.current[id].style.zIndex = Z_UNDER;
    }

    setGoast(null);
    settings.pieceDestinations && setMoveHelp(null);
    lastDragKey = null;
    id = null;
    currentKey = null;
  }

  function onStartDrag(e: MouseTouchEvent, data: DraggableData) {
    if (isSafari) {
      e.preventDefault();
    }
    currentKey = offsetToKey(data.x, data.y, playingWhite, squareSize);
    const piece = position[currentKey];
    if (
      piece &&
      (freeMove ||
        (playingWhite && piece.color === "white") ||
        (!playingWhite && piece.color === "black"))
    ) {
      const { color, role } = piece;
      id = piece.id;
      const pieceRef = pieceRefs.current[id];
      if (pieceRef) {
        pieceRef.style.zIndex = 300;
      }
      setGoast({
        goastPos: key2pos(currentKey),
        goastPiece: { color, role },
      });

      settings.pieceDestinations && setMoveHelp(possibleMoves[currentKey]);
    }
  }

  function startDraw(x, y) {
    const currentPos = [Math.floor(x / squareSize), Math.floor(y / squareSize)];
    setShapes([...shapes, { from: currentPos }]);
  }

  function onDraw(e: MouseTouchEvent, data: DraggableData) {
    const currentPos = [
      Math.floor(data.x / squareSize),
      Math.floor(data.y / squareSize),
    ];
    const newShapes = [...shapes];
    newShapes[newShapes.length - 1].to = currentPos;
    setShapes(newShapes);
  }
  function onStopDraw(e: MouseTouchEvent, data: DraggableData) {}

  return (
    <>
      <DraggableCore
        enableUserSelectHack={false}
        onStart={(e: MouseTouchEvent, data: DraggableData) => {
          if (!e.shiftKey) {
            onStartDrag(e, data);
          }
        }}
        onDrag={(e: MouseTouchEvent, data: DraggableData) => {
          drawMode ? onDraw(e, data) : onDrag(e, data);
        }}
        onStop={(e: MouseTouchEvent, data: DraggableData) => {
          drawMode ? onStopDraw(e, data) : onStop(e, data);
        }}
        onMouseDown={(e: MouseEvent) => {
          if (e.shiftKey) {
            setDrawMode(true);
            startDraw(e.nativeEvent.layerX, e.nativeEvent.layerY);
          } else {
            setDrawMode(false);
            setShapes([]);
          }
        }}
      >
        <div
          className={`${
            playingWhite ? "" : pieceStyles.flipped
          } flex w-full h-full`}
        >
          <DrawLayer
            lastPuzzleMove={lastPuzzleMove}
            goast={goast}
            moveSquarePos={moveSquarePos}
            moveHelp={moveHelp}
            position={position}
            highLightKey={highLightKey}
            playingWhite={playingWhite}
            showLastMove={showLastMove}
            lastMove={lastMove}
            lastMoveColor={lastMoveColor}
            settings={settings}
            puzzleDone={puzzleDone}
          />
          <SvgLayer
            shapes={shapes}
            bestMove={bestMove}
            playingWhite={playingWhite}
          />
          {children}
        </div>
      </DraggableCore>
    </>
  );
}

export default DragBoard;
